import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Row,
  Form,
  Input,
  Col,
  Select,
  notification,
  Popconfirm,
  Switch,
  Button,
  Divider,
  Tooltip,
  Checkbox,
  List,
  Popover
} from 'antd';
import Spinner from '../../CustomComponents/Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SubmitButton, SubmitButtonCol } from '../styledComponents.js';
import BreadCrumbComponent from './../../CustomComponents/BreadCrumbComponent';
import { SwapOutlined } from '@ant-design/icons';
import DragIndicatorIcon from '../../../Assets/Icons/DragIndicatorIcon.js';
import {
  HeadingButtonContainer,
  MappedValues,
  AddButton,
  SectionHeading,
  AddHeadingContainer,
  SmallSaveButton,
  MappedValuesContainer,
  HeadingDeleteButton,
  DeleteConfirmationMessage,
  HeadingTooltipButton,
  RestrictColumImport,
  DictationCol,
  TemplateButton,
  HeadRow,
  SectionHeading2,
  CustomDictationCheckBox
} from './styledComponents';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import 'react-phone-input-2/lib/style.css';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { PopoverGlobalStyle } from '../JobList/styledComponents';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal/index.jsx';
import {
  PlusOutlined,
  SearchOutlined,
  CaretDownOutlined,
  CaretUpOutlined
} from '@ant-design/icons';
import {
  getListOfCannedText,
  getCannedtextCollections
} from '../../../Apis/Author.js';
import { SearchInputStylee } from '../KeyToPhrase/styledComponents.js';
const { Option } = Select;
const { TextArea } = Input;
// const { Panel } = Collapse;
const AddDictationForm = ({
  supportedSections,
  selectedItem,
  submitHandler,
  setAddDrawerVisible,
  saveLoading,
  selectedItemColumn,
  handleSubmit,
  deleteModal,
  handleDeleteClick,
  handleRemoveFromSelected,
  handleActiveClick,
  deleteClick,
  onCancel,
  toggleActive
}) => {
  const [form] = Form.useForm();
  const [showAddNew, setShowAddNew] = useState(false);
  const [customUIHeading, setCustomUIHeading] = useState('');
  const [systemHeading, setSystemHeading] = useState(null);
  const [tooltipDescription, setTooltipDescription] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [sectionHeadings, setSectionHeadings] = useState([]);
  const [showAddHeading, setShowAddHeading] = useState(false);
  const [cannedTexts, setCannedTexts] = useState([]);
  const [customUIHeadingchange, setCustomUIHeadingchange] = useState([]);
  const [loader, setLoader] = useState(false);
  const deleteHeading = index => {
    sectionHeadings.splice(index, 1);
    setSectionHeadings([...sectionHeadings]);
  };
  const [exportToOtherAuthors, setExportToOtherAuthors] = useState(
    selectedItem ? selectedItem.exportToOtherAuthors : false
  );
  const user = useSelector(state => state.userReducer);
  const [globallyEnable, setGloballyEnable] = useState(
    selectedItem ? selectedItem.global : false
  );
  const stripHtmlTags = str => {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const [templateDrivenFlowEnabled, setTemplateDrivenFlowEnabled] = useState(
    selectedItem ? selectedItem.templateDrivenFlowEnabled : false
  );
  const [keytophraseEnabled, setKeytophraseEnabled] = useState(
    selectedItem ? selectedItem.keytophraseEnabled : false
  );
  const [required, setRequired] = useState(
    selectedItem ? selectedItem.required : false
  );
  const [appendToDefault, setAppendToDefault] = useState(
    selectedItem ? selectedItem.appendToDefault : false
  );
  const [cannedTextsWithHeadings, setCannedTextsWithHeadings] = useState([]);
  const [clickedItemIds, setClickedItemIds] = useState(new Set());
  const [activeEditor, setActiveEditor] = useState(null); // Use null as default state
  const quillRef1 = useRef(null); // Ref for the first editor
  const quillRef2 = useRef(null); // Ref for the second editor

  const handleSave = () => {
    onSave();
    setShowAddHeading(false);
  };
  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Dictation Template',
      path: '/dashboard/settings/dictation',
      onClick: () => {
        setAddDrawerVisible(false);
      }
    },
    {
      name: selectedItem ? 'Edit Template' : 'Create New Template',
      path: '/dashboard/settings/dictation'
    }
  ];

  const handleClick = editor => {
    setActiveEditor(editor);
  };
  const handleCancel = () => {
    setCustomUIHeading('');
    setSystemHeading('');
    setRequired(false);
    setDefaultValue('');
    setTooltipDescription('');
    setShowAddHeading(false);
    handleClearList();
  };
  const handleQuillChange = (content, customUIHeading) => {
    setCustomUIHeadingchange(customUIHeading);

    sectionHeadings.map(item =>
      item.customUIHeading === customUIHeading
        ? { ...item, defaultValue: content }
        : item
    );
  };
  const [searchQuery, setSearchQuery] = useState('');
  let timerId1;
  const handleSearch = event => {
    clearTimeout(timerId1);

    const { value } = event.target;

    timerId1 = setTimeout(async () => {
      try {
        setSearchQuery(value);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 1000);
  };

  const stripOuterParagraphTags = content => {
    if (content.startsWith('<p>') && content.endsWith('</p>')) {
      const strippedContent = content.substring(3, content.length - 4);
      if (
        !strippedContent.includes('<p>') &&
        !strippedContent.includes('</p>')
      ) {
        return strippedContent;
      }
    }
    return content;
  };
  const handleClearList = () => {
    setClickedItemIds(new Set());
  };
  const handleItemClick = item => {
    let quill = null;

    if (!showAddHeading) {
      quill = quillRef1.current ? quillRef1.current.getEditor() : null;
    } else if (showAddHeading) {
      quill = quillRef2.current ? quillRef2.current.getEditor() : null;
    }

    if (quill) {
      quill.focus(); // Ensure the editor is focused
      const range = quill.getSelection();

      if (range) {
        quill.insertText(range.index, ` ${item.key} `);
        quill.setSelection(range.index + ` ${item.key} `.length);
      } else {
        if (showAddHeading) {
          setSectionHeadings(prevHeadings =>
            prevHeadings.map(item =>
              item.customUIHeading === customUIHeadingchange
                ? {
                    ...item,
                    defaultValue:
                      (item.defaultValue || '') + ' ' + ' ' + item.key + ' '
                  }
                : item
            )
          );
          // setItemDefaultValue((itemDefaultValue || '') + ' ' + `\${${item.key}}`);
        } else if (!showAddHeading) {
          setDefaultValue((defaultValue || '') + ' ' + ` ${item.key} `);
        }
      }
    }
    setClickedItemIds(prevIds => new Set(prevIds.add(item.id)));
  };
  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike', 'clean']],
    clipboard: {
      matchVisual: false
    }
  };

  const updateCannedTextsWithHeadings = newHeading => {
    const updatedCannedTextsWithHeadings = [...cannedTextsWithHeadings];

    clickedItemIds.forEach(id => {
      const existingItem = updatedCannedTextsWithHeadings.find(
        item => item.cannedTextId === id
      );

      if (existingItem) {
        if (!existingItem.headings.includes(newHeading)) {
          existingItem.headings.push(newHeading);
        }
      } else {
        updatedCannedTextsWithHeadings.push({
          cannedTextId: id,
          headings: [newHeading]
        });
      }
    });

    setCannedTextsWithHeadings(updatedCannedTextsWithHeadings);
  };
  const onSave = () => {
    let newHeading = customUIHeading.trim();

    if (newHeading === '') {
      notification['error']({
        message: 'Failed',
        description: 'Section heading is mandatory'
      });
    } else if (!systemHeading) {
      notification['error']({
        message: 'Failed',
        description: 'Mapping value is mandatory'
      });
    } else {
      let isHeadingPresent = sectionHeadings.find(
        heading =>
          heading.customUIHeading.toLowerCase() === newHeading.toLowerCase()
      );
      if (isHeadingPresent) {
        notification['error']({
          message: 'Failed',
          description: 'Duplicate heading'
        });
      } else {
        updateCannedTextsWithHeadings(newHeading);
        let obj = {
          customUIHeading: newHeading,
          systemHeading: systemHeading,
          description: tooltipDescription,
          defaultValue: defaultValue,
          required: required,
          appendToDefault: appendToDefault
        };
        sectionHeadings.push(obj);
        setSystemHeading('');
        setCustomUIHeading('');
        setSectionHeadings(sectionHeadings);
        setSystemHeading(null);
        setRequired(false);
        setAppendToDefault(false);
        setTooltipDescription('');
        setDefaultValue('');
        setShowAddNew(false);
      }
    }
  };
  const isVisible =
    user.userType === 'admin' || user.userType === 'clinicAdmin';
  useEffect(() => {
    if (keytophraseEnabled) {
      getCannedtextCollections(0, 100, 'active', searchQuery)
        .then(response => {
          setCannedTexts(response.data.result);
          setLoader(false);
        })
        .catch(error => {
          console.error('Error fetching canned text:', error);
        });
    }
  }, [keytophraseEnabled, searchQuery]);
  const onChange = key => {};
  const handleButtonClick = (id, customUIHeading) => {
    setCustomUIHeadingchange(customUIHeading);

    getListOfCannedText(id)
      .then(response => {
        setCannedTexts(response.data.result);
      })
      .catch(error => {
        console.error('Error fetching template details:', error);
      });
  };

  useEffect(() => {}, [cannedTexts]);
  const TruncatedText = ({ text, maxLength }) => {
    if (typeof text === 'string' && text.length > maxLength) {
      const truncatedText = text.substring(0, maxLength) + '...';
      return (
        <Tooltip content={text}>
          <span title={text}>{truncatedText}</span>
        </Tooltip>
      );
    }
    return (
      <Tooltip content={text}>
        <span title={text}>{text}</span>
      </Tooltip>
    );
  };
  const [expandedId, setExpandedId] = useState(null);

  const toggleAccordion = id => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  const CustomLabel = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',

          color: '#080808'
        }}
      >
        Global availability
      </p>
      {/* <p
        style={{
          fontSize: '12px',
          lineHeight: '15.6px',
          color: '#5C5C5C'
        }}
      >
        Making it globally available will enable all users in the clinic to
        import this template.
      </p> */}
    </div>
  );

  const CustomAppendToDefault = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          marginBottom: '10px',
          color: '#080808'
        }}
      >
        Append To Default
      </p>
    </div>
  );
  const CustomLabelRequired = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          marginBottom: '10px',
          color: '#080808'
        }}
      >
        Required
      </p>
    </div>
  );
  const CustomLabelkeytophrase = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          marginBottom: '10px',
          color: '#080808'
        }}
      >
        Key to Phrase
      </p>
    </div>
  );
  const CustomLabelDirectional = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',

          color: '#080808'
        }}
      >
        Directional Flow
      </p>
    </div>
  );
  const cardStyle = {
    width: '90%',
    height: '42px',
    borderRadius: '8px',
    border: '1px solid #DEE3EF',
    padding: '8px',
    gap: '8px',
    backgroundColor: '#F5F7FA',
    display: 'flex',

    alignItems: 'center',

    justifyContent: 'flex-start'
  };

  const CustomLabelExport = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18.2px',
          color: '#080808'
        }}
      >
        Export and active to other authors
      </p>
    </div>
  );
  useEffect(() => {
    if (systemHeading && customUIHeading) {
    }
  }, [systemHeading, customUIHeading]);

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(selectedItem);
      setSectionHeadings([...selectedItem.sectionHeadings]);
      setCannedTextsWithHeadings([
        ...(selectedItem.cannedTextsWithHeadings || [])
      ]);
    }
  }, [selectedItem]);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={values => {
        if (sectionHeadings.length > 0) {
          if (showAddHeading) {
            notification['error']({
              message: 'Failed',
              description: 'Save or cancel the new heading section'
            });
          } else
            submitHandler(values, sectionHeadings, cannedTextsWithHeadings);
        } else
          notification['error']({
            message: 'Failed',
            description: 'Add atleast one section heading to save template'
          });
      }}
    >
      <HeadRow justify='space-between' align='middle'>
        <Col span={12}>
          <h2 className='templatecreation'>
            {selectedItem ? 'Edit Template ' : 'Create New Template '}
          </h2>
          <BreadCrumbComponent items={BreadCrubItems} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {selectedItem === null ? (
            <>
              <Button
                size='large'
                shape='round'
                onClick={onCancel}
                style={{ marginRight: '12px' }}
              >
                Cancel
              </Button>
              <SubmitButton
                htmlType='submit'
                type='primary'
                size='large'
                shape='round'
                loading={saveLoading}
              >
                Save
              </SubmitButton>
            </>
          ) : selectedItemColumn === 'Active' ? (
            <>
              <Button
                type='primary'
                size='large'
                shape='round'
                onClick={deleteClick}
                style={{ marginRight: '12px' }}
              >
                Delete
              </Button>
              {selectedItem.editable && (
                <SubmitButton
                  htmlType='submit'
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={handleSubmit}
                  style={{ marginRight: '12px' }}
                >
                  Save
                </SubmitButton>
              )}
              <AddButton
                type='primary'
                size='large'
                shape='round'
                onClick={e => handleRemoveFromSelected(e, selectedItem.id)}
              >
                Remove from Active List
              </AddButton>
            </>
          ) : selectedItemColumn === 'Mine' ? (
            <>
              <Button
                type='primary'
                size='large'
                shape='round'
                onClick={deleteClick}
                style={{ marginRight: '12px' }}
              >
                Delete
              </Button>
              {selectedItem.editable && (
                <AddButton
                  htmlType='submit'
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={handleSubmit}
                  style={{ marginRight: '12px' }}
                >
                  Save
                </AddButton>
              )}
              <AddButton
                type='primary'
                size='large'
                shape='round'
                onClick={handleActiveClick}
              >
                Add to Active List
              </AddButton>
            </>
          ) : null}
        </Col>
      </HeadRow>
      <Divider />
      <Row>
        <DictationCol
          span={13}
          style={{
            maxHeight: '80vh',
            overflow: 'auto'
          }}
        >
          <Row justify='center'>
            <Col span={24}>
              <Form.Item
                label='Template Name'
                name='templateName'
                validateTrigger={['onSubmit']}
                rules={[
                  { required: true, message: 'Template Name is required' },
                  {
                    required: true,
                    max: 250,
                    pattern: new RegExp(/^[^\s][\w\W]{0,249}[^\s]$/g),
                    message:
                      'Please enter valid name.(Name must not exceed 250 characters and must not contain leading and trailing space)'
                  }
                ]}
              >
                <Input
                  size='large'
                  placeholder='Enter the name of the Template'
                  readOnly={!(selectedItem ? selectedItem.editable : true)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='Description'
                name='templateDescription'
                validateTrigger={['onSubmit']}
                rules={[
                  {
                    required: true,
                    message: 'Template Description is required'
                  },
                  {
                    required: true,
                    max: 250,
                    pattern: new RegExp(/^[^\s][\w\W]{0,249}[^\s]$/g),
                    message:
                      'Please enter valid Description.(Description must not exceed 250 characters and must not contain leading and trailing space)'
                  }
                ]}
              >
                <Input
                  size='large'
                  placeholder='Add description of the Template here..'
                  readOnly={!(selectedItem ? selectedItem.editable : true)}
                />
              </Form.Item>
            </Col>
            <SectionHeading>
              <span className='headstyle'>Enter Heading</span>
            </SectionHeading>

            <div style={{ width: '100%' }}>
              {sectionHeadings && sectionHeadings.length > 0 ? (
                <div>
                  <MappedValuesContainer>
                    {sectionHeadings.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          padding: '20px',
                          marginBottom: '20px',
                          boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                        }}
                      >
                        <MappedValues form={true}>
                          <span one={true}>
                            <TruncatedText
                              text={item.customUIHeading}
                              maxLength={20}
                            />
                          </span>
                          <SwapOutlined />
                          <span two={true}>
                            <TruncatedText
                              text={item.systemHeading}
                              maxLength={22}
                            />
                            <Popconfirm
                              title={<>Delete Heading </>}
                              onConfirm={() => {
                                deleteHeading(index);
                              }}
                              okText='Yes'
                              cancelText='No'
                              overlayClassName='popover-delete-confirm'
                            >
                              <PopoverGlobalStyle />
                              <HeadingDeleteButton type='button'>
                                <DeleteOutlined />
                              </HeadingDeleteButton>
                            </Popconfirm>
                          </span>
                        </MappedValues>
                        <hr
                          style={{
                            border: '1px solid #e0e0e0',
                            margin: '20px 0'
                          }}
                        />
                        {templateDrivenFlowEnabled && (
                          <Row>
                            <Col span={12}>
                              <div
                                className='RestrictColum'
                                style={{
                                  backgroundColor: '#F0F2F5',
                                  padding: '12px',
                                  borderRadius: '8px',
                                  marginRight: '10px',
                                  marginTop: '5px'
                                }}
                              >
                                <Row style={{ marginBottom: '-60px' }}>
                                  <Col span={20}>
                                    <Form.Item
                                      label={<CustomLabelRequired />}
                                    ></Form.Item>
                                  </Col>

                                  <Col span={4}>
                                    <Form.Item name='required'>
                                      <Switch
                                        checked={item.required ? true : false}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div
                                className='RestrictColum'
                                style={{
                                  backgroundColor: '#F0F2F5',
                                  padding: '12px',
                                  borderRadius: '8px',

                                  marginTop: '5px'
                                }}
                              >
                                <Row style={{ marginBottom: '-60px' }}>
                                  <Col span={20}>
                                    <Form.Item
                                      label={<CustomAppendToDefault />}
                                    ></Form.Item>
                                  </Col>

                                  <Col span={4}>
                                    <Form.Item name='appendToDefault'>
                                      <Switch
                                        checked={
                                          item.appendToDefault ? true : false
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {item.defaultValue && templateDrivenFlowEnabled && (
                          <div style={{ marginBottom: '10px' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <SectionHeading2>
                                <span className='headstyle2'>
                                  Default Value
                                </span>
                              </SectionHeading2>
                            </div>
                            <ReactQuill
                              ref={quillRef1}
                              value={item.defaultValue}
                              readOnly={true}
                              onChange={content =>
                                handleQuillChange(content, item.customUIHeading)
                              }
                              onClick={() => {
                                setActiveEditor('editor1');

                                setCustomUIHeadingchange(item.customUIHeading);
                              }}
                              theme='snow'
                              modules={modules}
                              style={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '8px',
                                marginTop: '5px',
                                width: '100%'
                              }}
                            />
                          </div>
                        )}
                        {item.description && templateDrivenFlowEnabled && (
                          <div style={{ marginBottom: '10px' }}>
                            <SectionHeading2>
                              <span className='headstyle2'>Tooltip Text</span>
                            </SectionHeading2>
                            <TextArea
                              rows={4}
                              value={item.description}
                              placeholder='Tooltip Description'
                              style={{
                                backgroundColor: '#F0F2F5',
                                padding: '10px',
                                borderRadius: '8px',
                                marginTop: '5px',
                                width: '100%'
                              }}
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </MappedValuesContainer>
                </div>
              ) : null}

              <div>
                {showAddHeading && (
                  <AddHeadingContainer>
                    <Row
                      style={{
                        marginBottom: '10px',
                        backgroundColor: '#DDE3EB',
                        padding: '10px',
                        borderRadius: '8px',
                        alignItems: 'center'
                      }}
                    >
                      <Col span={12}>
                        <h4 style={{ margin: 0 }}>New Heading</h4>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                          onClick={handleCancel}
                          style={{ marginRight: '10px' }}
                        >
                          Cancel
                        </Button>
                        <Button type='primary' onClick={handleSave}>
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <div
                      className='AddheadingOuterSection'
                      style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px'
                      }}
                    >
                      <Col span={24}>
                        <Row>
                          <Input
                            size='large'
                            placeholder='Add heading'
                            onChange={e => {
                              setCustomUIHeading(e.target.value);
                            }}
                            style={{ flex: '1', marginRight: '5px' }}
                            value={customUIHeading}
                          />
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <SwapOutlined />
                          </div>
                          <Select
                            placeholder='Section'
                            onSelect={val => {
                              setSystemHeading(val);
                            }}
                            style={{ flex: '1', marginLeft: '5px' }}
                            value={systemHeading}
                          >
                            {supportedSections.map(item => {
                              return <Option value={item}>{item}</Option>;
                            })}
                          </Select>
                        </Row>
                        {templateDrivenFlowEnabled && (
                          <Row>
                            <Col span={12}>
                              <div
                                className='RestrictColum'
                                style={{
                                  backgroundColor: '#F5F7FA',
                                  padding: '12px',
                                  borderRadius: '8px',
                                  marginRight: '10px',
                                  marginBottom: '7px',
                                  marginTop: '12px'
                                }}
                              >
                                <Row style={{ marginBottom: '-60px' }}>
                                  <Col span={20}>
                                    <Form.Item
                                      label={<CustomLabelRequired />}
                                    ></Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      name='required'
                                      style={{
                                        marginBottom: 0,
                                        marginRight: '0px'
                                      }}
                                    >
                                      <Switch
                                        checked={required}
                                        onChange={() => {
                                          form.setFieldValue({
                                            ...form.getFieldsValue(),
                                            required: !required
                                          });
                                          setRequired(!required);
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div
                                className='RestrictColum'
                                style={{
                                  backgroundColor: '#F0F2F5',
                                  padding: '12px',
                                  borderRadius: '8px',

                                  marginTop: '12px'
                                }}
                              >
                                <Row style={{ marginBottom: '-60px' }}>
                                  <Col span={20}>
                                    <Form.Item
                                      label={<CustomAppendToDefault />}
                                    ></Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      name='appendToDefault'
                                      style={{
                                        marginBottom: 0,
                                        marginRight: '0px'
                                      }}
                                    >
                                      <Switch
                                        checked={appendToDefault}
                                        onChange={() => {
                                          form.setFieldValue({
                                            ...form.getFieldsValue(),
                                            appendToDefault: !appendToDefault
                                          });
                                          setAppendToDefault(!appendToDefault);
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        )}

                        {templateDrivenFlowEnabled && (
                          <Row>
                            <SectionHeading2>
                              <span className='headstyle2'>Default Text</span>
                            </SectionHeading2>

                            <div
                              className='EditorArea'
                              style={{ width: '100%', marginTop: '5px' }}
                            >
                              <ReactQuill
                                ref={quillRef2}
                                modules={modules}
                                value={defaultValue}
                                onChange={content => setDefaultValue(content)}
                                onClick={() => {
                                  setActiveEditor('editor2');
                                }}
                                placeholder='Add default Text (optional)'
                                theme='snow'
                                style={{
                                  minHeight: '100px',
                                  marginTop: '5px',
                                  width: '100%'
                                }}
                              />
                            </div>
                          </Row>
                        )}

                        {templateDrivenFlowEnabled && (
                          <Row>
                            <SectionHeading2>
                              <span className='headstyle2'>Tooltip Text</span>
                            </SectionHeading2>
                            <div style={{ width: '100%', marginTop: '5px' }}>
                              <Input.TextArea
                                rows={4}
                                placeholder='Add tooltip Text (optional)'
                                onChange={e =>
                                  setTooltipDescription(e.target.value)
                                }
                                style={{ width: '100%' }}
                                value={tooltipDescription}
                              />
                            </div>
                          </Row>
                        )}
                      </Col>
                    </div>
                  </AddHeadingContainer>
                )}
                {!showAddHeading &&
                  (selectedItem ? selectedItem.editable : true) && (
                    <Col span={24}>
                      <TemplateButton
                        onClick={() => {
                          setShowAddHeading(true);
                          // handleClearList();
                        }}
                      >
                        <span className='icon-wrapper'>
                          <PlusOutlined />
                        </span>
                        New Heading
                      </TemplateButton>
                    </Col>
                  )}
              </div>
            </div>
          </Row>
        </DictationCol>

        <RestrictColumImport
          span={11}
          style={{
            maxHeight: '80vh',
            overflow: 'hidden',
            minHeight: '51vh'
          }}
        >
          <div className='configurationHead'>Configuration</div>
          <div
            className='RestrictColum'
            style={{
              backgroundColor: '#F5F7FA',
              padding: '12px',
              borderRadius: '8px',
              marginBottom: '15px'
            }}
          >
            <Col span={24}>
              <Row style={{ marginBottom: '-60px' }}>
                <Col span={22}>
                  <Form.Item label={<CustomLabel />}></Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item
                    name='global'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                  >
                    {/* <Switch
                      checked={globallyEnable}
                      onChange={() => {
                        form.setFieldValue({
                          ...form.getFieldsValue(),
                          global: !globallyEnable
                        });
                        setGloballyEnable(!globallyEnable);
                      }}
                    
                    /> */}
                    <Switch
                      checked={globallyEnable}
                      onChange={() => {
                        form.setFieldValue({
                          ...form.getFieldsValue(),
                          global: !globallyEnable
                        });
                        setGloballyEnable(!globallyEnable);
                      }}
                      disabled={!(selectedItem ? selectedItem.editable : true)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>
          <div
            className='RestrictColum'
            style={{
              backgroundColor: '#F5F7FA',
              padding: '12px',
              borderRadius: '8px',
              marginBottom: '10px'
            }}
          >
            <Col span={24}>
              <Row style={{ marginBottom: '-60px' }}>
                <Col span={22}>
                  <Form.Item label={<CustomLabelDirectional />}></Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item
                    name='templateDrivenFlowEnabled'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                  >
                    <Switch
                      checked={templateDrivenFlowEnabled}
                      onChange={() => {
                        form.setFieldValue({
                          ...form.getFieldsValue(),
                          templateDrivenFlowEnabled: !templateDrivenFlowEnabled
                        });
                        setTemplateDrivenFlowEnabled(
                          !templateDrivenFlowEnabled
                        );
                      }}
                      disabled={!(selectedItem ? selectedItem.editable : true)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </div>
          <div
            style={{
              backgroundColor: '#F5F7FA',
              padding: '12px',
              borderRadius: '8px',
              marginBottom: '10px'
            }}
          >
            <Col span={24}>
              <Row style={{ marginBottom: '-60px' }}>
                <Col span={22}>
                  <Form.Item label={<CustomLabelkeytophrase />}></Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item
                    name='keytophraseEnabled'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                  >
                    <Switch
                      checked={keytophraseEnabled}
                      onChange={() => {
                        const newValue = !keytophraseEnabled;
                        setLoader(newValue);
                        form.setFieldsValue({
                          keytophraseEnabled: newValue
                        });
                        setKeytophraseEnabled(newValue);
                      }}
                      disabled={!(selectedItem ? selectedItem.editable : true)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <div>
              {keytophraseEnabled && (
                <div>
                  <SearchInputStylee
                    placeholder='Search Keys or Phrases'
                    prefix={<SearchOutlined />}
                    onChange={handleSearch}
                    style={{
                      marginBottom: '10px',
                      width: '88%'
                    }}
                  />

                  {/* <div style={{ height: '211px', overflowY: 'auto' }}>
                    <List
                      dataSource={cannedTexts}
                      renderItem={item => (
                        <List.Item
                          key={item.id}
                          onClick={() => handleItemClick(item)}
                          style={{ padding: '5px', border: 'none' }}
                        >
                          <span style={cardStyle}>
                            <DragIndicatorIcon style={{ color: '#000000' }} />
                            {item.key}
                            <Tooltip title={stripHtmlTags(item.phrase)}>
                              <InfoCircleOutlined
                                style={{ color: '#000000', marginLeft: 'auto' }}
                              />
                            </Tooltip>
                          </span>
                        </List.Item>
                      )}
                    />
                    
                  </div> */}
                  {loader ? (
                    <Spinner height={'25vh'} />
                  ) : (
                    <>
                      <div style={{ height: '211px', overflowY: 'auto' }}>
                        <List
                          dataSource={cannedTexts}
                          renderItem={item => (
                            <List.Item
                              key={item.id}
                              onClick={() => handleItemClick(item)}
                              style={{ padding: '5px', border: 'none' }}
                            >
                              <span style={cardStyle}>
                                <DragIndicatorIcon
                                  style={{ color: '#000000' }}
                                />
                                {item.key}
                                <span
                                  onClick={() => toggleAccordion(item.id)}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto'
                                  }}
                                >
                                  {expandedId === item.id ? (
                                    <DownOutlined />
                                  ) : (
                                    <RightOutlined />
                                  )}
                                </span>
                              </span>
                              {expandedId === item.id && (
                                <div
                                  style={{
                                    marginTop: '7px',
                                    marginRight: 'auto'
                                  }}
                                >
                                  <Tooltip
                                    title={stripHtmlTags(item.phrase)}
                                    visible={true}
                                  ></Tooltip>
                                </div>
                              )}
                            </List.Item>
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {isVisible && (selectedItem ? selectedItem.editable : true) && (
            <Row style={{ marginBottom: '-60px' }}>
              <Col span={24}>
                <Form.Item
                  name='exportToOtherAuthors'
                  style={{ marginBottom: 0, marginRight: '0px' }}
                  className='custom-checkbox'
                >
                  <Checkbox
                    checked={exportToOtherAuthors}
                    onChange={() => {
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        exportToOtherAuthors: !exportToOtherAuthors
                      });
                      setExportToOtherAuthors(!exportToOtherAuthors);
                    }}
                  >
                    Activate for all users
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
        </RestrictColumImport>
      </Row>
      <ConfirmationModal
        visible={deleteModal}
        onOk={handleDeleteClick}
        onCancel={toggleActive}
        centered
        // loading={deleteLoading}
        confirmation={true}
        antdModalProps={{ closable: false, mask: false }}
      >
        <DeleteConfirmationMessage>
          Are you sure you want to delete?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
      ;
    </Form>
  );
};

export default AddDictationForm;
