import React, { useEffect, useState, useCallback } from 'react';
import PageTitle from '../../../Utils/PageTitle';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Spinner from '../../CustomComponents/Spinner';
import { getUserFacilities } from '../../../Apis/User';
import { useHistory } from 'react-router-dom';
import {
  CustomModal,
  K2pButtonsecnd,
  SearchInputStylee,
  SearchDivider
} from '../KeyToPhrase/styledComponents';

import {
  COLUMN_NAMES,
  Column,
  MovableItem
} from '../KeyToPhrase/DragAndDropComponent';
import { CardTitle, StyledSelect } from '../KeyToPhrase/styledComponents';
import { CloseOutlined } from '@ant-design/icons';
import { SearchInputStyle, CloseButton } from '../styledComponents';
import {
  TemplateWrapper,
  MappedValues,
  AddButton,
  TemplateName,
  TemplateActionButton,
  DeleteConfirmationMessage,
  ConfigDictationRow,
  DnDColumn,
  StyledDiv,
  SubmitCol,
  ActiveCol,
  CustomDictationModal,
  KpCol,
  ActiveSectionCol,
  DictationMineCol,
  MappedValuesContainer
} from './styledComponents';
import { ContainerRow, TitleRow } from '../styledComponents';
import { useSelector } from 'react-redux';
import { Keytophraseduplication } from '../KeyToPhrase/styledComponents';
import {
  PlusOutlined,
  DownloadOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  ArrowDownOutlined,
  SwapOutlined
} from '@ant-design/icons';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';
import AddDictationForm from './AddDictationForm';
import {
  notification,
  Tooltip,
  Row,
  Col,
  Modal,
  Divider,
  message,
  Button,
  Checkbox
} from 'antd';
import { useDebounce } from '../../../Utils/Debounce';
import { SEARCH_DELAY } from '../../../Constants/StringConstants';

import { ActiveKeytophraseCol } from '../KeyToPhrase/styledComponents';
import {
  saveDictationTemplate,
  editDictationTemplate,
  myCollectionToActive,
  listDictationTemplate,
  removeTemplatesFromActiveList,
  authorsCollectionToMyCollectionDict,
  deleteDictationTemplateedit,
  getSupportedSections,
  getListOfCannedText,
  removeDictationTemplate
} from '../../../Apis/Author';
import BiDirectionalArrow from '../../../Assets/Icons/BiDirectionalArrow';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import BreadCrumbComponent from './../../CustomComponents/BreadCrumbComponent';

import { useNavigate } from 'react-router-dom';
const DictationTemplate = () => {
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [supportedSections, setSupportedSections] = useState([]);
  const [dictationTemplate, setDictationTemplate] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [checkedCardIds, setCheckedCardIds] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [loadingActive, setLoadingActive] = useState(false);
  const [dictationForm, setDictationForm] = useState(false);
  const [cannedTextAuthorSize, setCannedTextAuthorSize] = useState(0);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActiveScroll, setLoadingActiveScroll] = useState(false);
  const [loadingMineScroll, setLoadingMineScroll] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const theme = useSelector(state => state.themeReducer);
  const [selectedPhrase, setSelectedPhrase] = useState();
  const [loader, setLoader] = useState(false);
  const [selectedFacilityItem, setSelectedFacilityItem] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [fetchDataFlagAuthor, setFetchDataFlagAuthor] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemColumn, setSelectedItemColumn] = useState(null);
  const [loadingAuthor, setLoadingAuthor] = useState(false);
  const [fetchDataFlagActive, setFetchDataFlagActive] = useState(true);
  const [checkedCards, setCheckedCards] = useState([]);
  const [cannedTextActiveSize, setCannedTextActiveSize] = useState(0);
  const [cannedTextAuthor, setCannedTextsAuthor] = useState([]);
  const [minePage, setMinePage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryAuthor, setSearchQueryAuthor] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [authPage, setAuthPage] = useState(0);
  const toggleTable = () => {
    setShowTable(false);
  };

  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Dictation Template',
      path: '/dashboard/settings/dictation',
      onClick: () => {
        setShowTable(true);
      }
    }
  ];
  if (!showTable) {
    BreadCrubItems.push({
      name: 'Configure'
    });
  }
  const toggleErrorModal = () => {
    setErrorModalVisible(!errorModalVisible);

    // setAuthPage(0);
  };
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  const isConflict = () => {
    const isItemAlreadyPresent = checkedCardIds.some(cardId => {
      const card = cannedTextAuthor.find(item => item.id === cardId);
      return card && card.conflict === true;
    });

    if (isItemAlreadyPresent) {
      toggleErrorModal();
      return;
    } else {
      handleImport();
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [facilityIds, setFacilityIds] = useState();
  const [facilities, setFacilities] = useState([]);
  const [cannedTextMineSize, setCannedTextsMineSize] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fetchDataFlagMine, setFetchDataFlagMine] = useState(true);
  const { Option } = StyledSelect;
  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };
  const { KEY_TO_PHRASE, ACTIVE_KEY_TO_PHRASE } = COLUMN_NAMES;
  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );
  const getKeytoPhraseItems = items => {
    listDictationTemplate('mine').then(res => {
      // const array2 = res.data.result;
      // const array1 = items;
      const keys = res.data.result.map(item => {
        return { ...item, column: COLUMN_NAMES.KEY_TO_PHRASE };
      });

      setKeyToPhraseItems(keys);
    });
  };
  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardClick = card => {
    // setItemEditable()
    setSelectedCard(card);
  };
  const TruncatedText = ({ text, maxLength }) => {
    // Check if the text length is greater than the maxLength
    if (typeof text === 'string' && text.length > maxLength) {
      // If it is, truncate the text and add an ellipsis
      const truncatedText = text.substring(0, maxLength) + '...';
      return <span>{truncatedText}</span>;
    }
    return <span>{text}</span>;
  };
  const handleCancelAction = () => {
    setIsModalVisible(false);
  };
  const returnItemsForColumn1 = columnName => {
    return (
      keyToPhraseItems
        // .filter(item => item.column === columnName)
        .map((item, index) => (
          <MovableItem
            templateName={item.id}
            id={item.id}
            name={
              <StyledDiv direction='column'>
                <h4 className='keyvalue'>
                  <TruncatedText text={item.templateName} maxLength={50} />
                </h4>
                <h4>Headings</h4>
                <div className='sectionHeadings'>
                  <TruncatedText
                    text={item.sectionHeadings
                      .map(item => item.customUIHeading)
                      .join(', ')}
                    maxLength={50} // Adjust maxLength as needed
                  />
                </div>
              </StyledDiv>
            }
            currentColumnName={item.column}
            setItems={setSelectedFacilityItem}
            index={index}
            moveCardHandler={moveCardHandler}
            handleItemClick={() => handleItemClick(item, 'Mine')}
            saveCannedTextToFacility={saveCannedTextToFacility}
            data={item}
          />
        ))
    );
  };
  const onCancel = () => {
    // if (selectedItem) setSectionHeadings([...selectedItem.sectionHeadings]);
    setAddDrawerVisible(false);
    // setDictationForm(false);
    setSelectedItem(null);
  };
  const handleOpenModal = (e, id) => {
    e.stopPropagation(); // Stop event propagation to prevent triggering the parent click event
    const removeItem = selectedFacilityItem.find(item => item.id === id);
    setSelectedItem(removeItem);
    setIsModalVisible(true);
  };
  const handleImport = (overRideExisting = false) => {
    authorsCollectionToMyCollectionDict(checkedCardIds, overRideExisting).then(
      res => {
        if (res?.data?.success) {
          notification.success({ message: res?.data?.message });
          setModalVisible(false);
          setCheckedCards([]);
          setCheckedCardIds([]);
          setSelectedCard(null);
          setKeyToPhraseItems([]);
          setCannedTextsMineSize(0);

          setMinePage(0);

          setFetchDataFlagMine(true);
          setErrorModalVisible(false);

          // fetchMineData();
        } else {
          notification.error({ message: res?.data?.message });
        }
      }
    );
  };
  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = keyToPhraseItems[dragIndex];

    if (dragItem) {
      setKeyToPhraseItems(prevState => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };
  const submitHandler = (val, sectionHeadings, cannedTextsWithHeadings) => {
    let data = {
      templateDescription: val.templateDescription,
      templateName: val.templateName,
      sectionHeadings: sectionHeadings,
      cannedTextsWithHeadings: cannedTextsWithHeadings,
      global: val.global,
      active: selectedItem ? selectedItem.active : val.active,
      templateDrivenFlowEnabled: val.templateDrivenFlowEnabled,
      exportToOtherAuthors: val.exportToOtherAuthors,
      required: val.required,
      defaultValue: val.defaultValue
    };
    setSaveLoading(true);
    if (selectedItem) {
      data.id = selectedItem.id;
      editDictationTemplate(data).then(response => {
        if (response.data.success) {
          setAddDrawerVisible(false);
          setSelectedItem(null);
          // fetchDictationTemplate(editItem.id);
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setSaveLoading(false);
          Object.keys(data).forEach(key => {
            if (selectedItem.hasOwnProperty(key)) {
              selectedItem[key] = data[key];
            }
          });
          if (selectedItem.active) {
            setSelectedFacilityItem([]);
            setCannedTextActiveSize(0);

            setActivePage(0);

            setFetchDataFlagActive(true);

            setCurrentPage(0);
          } else {
            setKeyToPhraseItems([]);

            setMinePage(0);

            setFetchDataFlagMine(true);

            setCurrentPage(0);
          }
        } else {
          setSaveLoading(false);
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });
    } else {
      saveDictationTemplate(data)
        .then(response => {
          if (response.data.success) {
            fetchDictationTemplateMine();
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
            setAddDrawerVisible(false);
            setSaveLoading(false);
          } else {
            setSaveLoading(false);
            notification['error']({
              message: 'Failes',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setSelectedCard(cannedTextAuthor[0]);
  }, [cannedTextAuthor]);

  useEffect(() => {
    setFetchDataFlagMine(true);
  }, [searchQuery]);
  useEffect(() => {
    if (minePage === 0 && fetchDataFlagMine) {
      setLoading(true);
      fetchDictationTemplateMine();
      setFetchDataFlagMine(false);
    }
    if (activePage === 0 && fetchDataFlagActive) {
      setLoadingActive(true);
      fetchDictationTemplateActive();
      setFetchDataFlagActive(false);
    }
  }, [minePage, activePage, fetchDataFlagMine, fetchDataFlagActive]);
  let timerId1;
  const handleSearch = event => {
    clearTimeout(timerId1);
    const { value } = event.target;
    timerId1 = setTimeout(async () => {
      try {
        setKeyToPhraseItems([]);
        setMinePage(0); // Reset page when performing a new search
        setSearchQuery(value);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 1000);
  };
  const toggleActive = () => {
    setDeleteModal(false);
  };
  const handleScroll = e => {
    // e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 5
    ) {
      fetchMoreData();
    }
  };
  const handleScrollActive = e => {
    // e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 1
    ) {
      fetchMoreDataActive();
    }
  };
  const handleScrollAuthor = e => {
    // e.persist();

    if (
      e.target.scrollHeight - e.target.scrollTop <
      e.target.clientHeight + 2
    ) {
      fetchMoreDataAuthor();
    }
  };
  const fetchMoreData = () => {
    if (loadingMineScroll) {
      return;
    }
    if (cannedTextMineSize > keyToPhraseItems.length) {
      setLoadingMineScroll(true);
      fetchDictationTemplateMine();
    }
  };
  const fetchMoreDataAuthor = () => {
    if (loading) {
      return;
    }
    if (cannedTextAuthorSize > cannedTextAuthor.length) {
      // setLoading(true);
      fetchAuthorData();
    }
  };
  const fetchMoreDataActive = () => {
    if (loadingActiveScroll) {
      return;
    }
    if (cannedTextActiveSize > selectedFacilityItem.length) {
      // setLoading(true);
      setLoadingActiveScroll(true);
      fetchDictationTemplateActive();
    }
  };
  const [keyToPhraseItems, setKeyToPhraseItems] = useState([]);
  const fetchDictationTemplateMine = templateId => {
    // setLoading(true);

    listDictationTemplate('mine', minePage, 50, searchQuery)
      .then(response => {
        if (response.data.success) {
          let templates = response.data.result;

          const filteredKeys = templates.filter(
            newItem =>
              !keyToPhraseItems.some(oldItem => oldItem.id === newItem.id)
          );

          setKeyToPhraseItems(prevItems => [...prevItems, ...filteredKeys]);
          setCannedTextsMineSize(response.data.size);
          if (response.data.result.length == 50) {
            setMinePage(prevPage => prevPage + 1);
          }
          setLoadingMineScroll(false);
        }
        setLoading(false);
        setLoadingMineScroll(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  const fetchDictationTemplateActive = templateId => {
    // setLoadingActive(true);
    listDictationTemplate('active', activePage, 50, searchString)
      .then(response => {
        if (response.data.success) {
          let templates = response.data.result;
          // if (templateId) {
          //   setSelectedItem(
          //     templates.find(template => template.id === templateId)
          //   );
          // } else setSelectedItem(templates[0]);
          // setSelectedFacilityItem(templates);
          const filteredKeys = templates.filter(
            newItem =>
              !selectedFacilityItem.some(oldItem => oldItem.id === newItem.id)
          );

          setSelectedFacilityItem(prevItems => [...prevItems, ...filteredKeys]);
          setCannedTextActiveSize(response.data.size);
          if (response.data.result.length == 50) {
            setActivePage(prevPage => prevPage + 1);
          }
          setLoadingActive(false);
          setLoadingActiveScroll(false);
        }
        setLoadingActive(false);
        setLoadingActiveScroll(false);
      })
      .catch(error => {
        setLoadingActive(false);
        console.log(error);
      });
  };
  const handleConfirmAction = e => {
    handleRemoveFromSelected(e, selectedItem.id);
  };
  const handleRemoveFromSelected = (e, id) => {
    e.stopPropagation();

    removeTemplatesFromActiveList(id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });
        const removedItem = selectedFacilityItem.find(item => item.id === id);
        const data = selectedFacilityItem.filter(
          cannedItem => cannedItem.id !== id
        );
        setSelectedFacilityItem(data);
        setIsModalVisible(false);
        setAddDrawerVisible(false);
        setKeyToPhraseItems(prevItems => [...prevItems, removedItem]);
        setMinePage(0);
        setActivePage(0);
      } else {
        notification.error({ message: res?.data?.message });
      }
    });
  };
  const debouncedHandleScrollActive = debounce(handleScrollActive, 300);
  window.addEventListener('scroll', debouncedHandleScrollActive);
  const KeyPhraseCard = ({
    id,
    facilityKey,
    templateDescription,
    cardClosable = false,
    handleItemClick
  }) => {
    return (
      <StyledDiv
        width='100%'
        className='movable-item1'
        direction='column'
        onClick={handleItemClick}
        style={{ height: '105px' }}
      >
        <StyledDiv width='100%' justify={'space-between'}>
          <h4 className='keyvalue'>{facilityKey}</h4>
          {cardClosable && (
            <CloseButton
              onClick={e => handleOpenModal(e, id)}
              icon={<CloseOutlined />}
            ></CloseButton>
          )}
          <ConfirmationModal
            visible={isModalVisible}
            onOk={e => handleConfirmAction(e)}
            onCancel={handleCancelAction}
            maskStyle={{ backgroundColor: 'transparent' }}
            centered
            // loading={deleteLoading}
            confirmation={true}
            antdModalProps={{ closable: false, mask: false }}
          >
            <DeleteIcon />
            <DeleteConfirmationMessage>
              Are you sure you want to remove from Active list?
            </DeleteConfirmationMessage>
          </ConfirmationModal>
        </StyledDiv>

        <h4>Headings</h4>
        <div className='sectionHeadings'>
          <TruncatedText
            text={templateDescription
              .map(item => item.customUIHeading)
              .join(', ')}
            maxLength={30} // Adjust maxLength as needed
          />
        </div>
      </StyledDiv>
    );
  };
  const returnItemsForColumn = columnName => {
    return selectedFacilityItem.map((item, index) => (
      <KeyPhraseCard
        templateName={index}
        id={item.id}
        facilityKey={item.templateName}
        templateDescription={item.sectionHeadings}
        cardClosable={true}
        saveCannedTextToFacility={() => saveCannedTextToFacility()}
        handleItemClick={() => handleItemClick(item, 'Active')}
      />
    ));
  };
  const handleActiveClick = () => {
    myCollectionToActive(selectedItem.id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });
        const removedItem = keyToPhraseItems.find(
          item1 => item1.id === selectedItem.id
        );
        const data = keyToPhraseItems.filter(
          cannedItem => cannedItem.id !== selectedItem.id
        );
        setKeyToPhraseItems(data);
        //setSelectedFacilityItem(prevItems => [removedItem, ...prevItems]);
        setSelectedFacilityItem([]);

        setCannedTextActiveSize(0);

        setActivePage(0);

        setFetchDataFlagActive(true);

        setCurrentPage(0);
        setMinePage(0);
      } else {
        notification.error({ message: res?.data?.message });
      }
    });

    setAddDrawerVisible(false);
  };
  const handleItemClick = (item, type) => {
    setSelectedItemColumn(type);
    setSelectedItem(item);
    if (!isModalVisible) {
      setAddDrawerVisible(true);
    }
  };
  const handleCancel = () => {
    setModalVisible(false);
    setSelectedCard(null);
  };

  const activeItems = returnItemsForColumn(ACTIVE_KEY_TO_PHRASE);
  const deleteTemplate = () => {
    setDeleteLoading(true);
    deleteDictationTemplateedit(deleteItem.id)
      .then(response => {
        if (response.data.success) {
          // fetchDictationTemplate();
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setDeleteItem(null);
          setShowDeleteConfirmation(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
        setDeleteLoading(false);
      })
      .catch(err => {
        setDeleteLoading(false);
        console.log(err);
      });
  };
  const deleteClick = () => {
    setDeleteModal(true);
  };
  const handleDeleteClick = () => {
    setSaveLoading(true);

    removeDictationTemplate(selectedItem.id)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setSaveLoading(false);
          setAddDrawerVisible(false);

          const data = keyToPhraseItems.filter(
            cannedItem => cannedItem.id !== selectedItem.id
          );
          setKeyToPhraseItems(data);
          setDeleteModal(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setSaveLoading(false);
          // setAddDrawerVisible(false);
          setDeleteModal(false);
        }
      })
      .catch(err => {
        setSaveLoading(false);
        console.log(err);
      });
  };

  // const handleCreate = () => {
  //   setSelectedItem(null);
  //   setAddDrawerVisible(true);
  // };
  const history = useHistory();
  const handleCreate = () => {
    setSelectedItem(null);
    setAddDrawerVisible(true);
  };
  useEffect(() => {
    getSupportedSections()
      .then(response => {
        if (response.data.success) setSupportedSections(response.data.result);
      })
      .catch(error => console.log(error));
  }, []);

  const saveCannedTextToFacility = item => {
    myCollectionToActive(item.data.id).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });
        const removedItem = keyToPhraseItems.find(
          item1 => item1.id === item.id
        );
        const data = keyToPhraseItems.filter(
          cannedItem => cannedItem.id !== item.data.id
        );
        setKeyToPhraseItems(data);
        setSelectedFacilityItem(prevItems => {
          if (!prevItems.includes(removedItem)) {
            return [...prevItems, removedItem];
          }

          return prevItems;
        });
        // setMinePage(0);
        // setActivePage(0);
      } else {
        notification.error({ message: res?.data?.message });
      }
    });
  };
  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        const enabledFacilities = response.data.result.filter(facility => {
          return !facility.disabled;
        });
        setFacilities(enabledFacilities);
        setFacilityIds(enabledFacilities[0]?.facilityId);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  const handleSkip = () => {
    handleImport();

    // setAuthPage(0);
  };
  const handleReplace = () => {
    handleImport(true);

    // setAuthPage(0);
  };
  useEffect(() => {
    if (!initialRender) {
      if (authPage === 0 && fetchDataFlagAuthor) {
        setFetchDataFlagAuthor(false);

        fetchAuthorData();
      }
    } else {
      setInitialRender(false);
    }
  }, [searchQueryAuthor, fetchDataFlagAuthor, authPage, cannedTextAuthor]);
  const handleSearchAuthor = event => {
    clearTimeout(timerId1);

    const { value } = event.target;

    // Reset page when performing a new search
    timerId1 = setTimeout(async () => {
      try {
        setCannedTextsAuthor([]);
        setAuthPage(0);
        setSearchQueryAuthor(value);
        setFetchDataFlagAuthor(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 1000);
  };
  const fetchAuthorData = async () => {
    setFetchDataFlagAuthor(false);

    setModalVisible(true);
    setLoadingAuthor(true);

    try {
      const mineRes = await listDictationTemplate(
        // authPage,

        'author',
        authPage,
        50,
        searchQueryAuthor
        // searchQueryAuthor
      );
      const keys = mineRes.data.result.map(item => ({
        ...item
      }));
      const filteredKeys = keys.filter(
        newItem => !cannedTextAuthor.some(oldItem => oldItem.id === newItem.id)
      );

      setCannedTextsAuthor(prevItems => [...prevItems, ...filteredKeys]);

      setCannedTextAuthorSize(mineRes.data.size);

      if (mineRes.data.result.length == 50) {
        // setAuthPage(prevPage => prevPage + 1);
      }

      setLoadingAuthor(false);
    } catch (error) {
      console.error('Error fetching data:', error);

      setLoadingAuthor(false);
    }
  };
  const removeSpaces = str => {
    // Remove all spaces from a string
    return str.replace(/\s/g, '');
  };
  const handleCheckboxChange = (card, checked) => {
    if (checked) {
      const similarKeyItem = checkedCards.find(
        item =>
          removeSpaces(item.templateName) === removeSpaces(card.templateName)
      );
      if (similarKeyItem) {
        message.error('This card is already checked!');
      } else {
        setCheckedCards([...checkedCards, card]);
      }
    } else {
      // If the checkbox is unchecked, remove the item from the checkedCards array
      setCheckedCards(checkedCards.filter(item => item.id !== card.id));
    }
    if (checkedCardIds.includes(card.id)) {
      setCheckedCardIds(checkedCardIds.filter(id => id !== card.id));
    } else {
      setCheckedCardIds([...checkedCardIds, card.id]);
    }
  };
  return (
    <>
      <PageTitle title='Dictation Template' />
      {addDrawerVisible ? (
        <AddDictationForm
          supportedSections={supportedSections}
          selectedItem={selectedItem}
          selectedItemColumn={selectedItemColumn}
          deleteClick={deleteClick}
          deleteModal={deleteModal}
          submitHandler={submitHandler}
          onCancel={onCancel}
          selectedPhrase={selectedPhrase}
          setAddDrawerVisible={setAddDrawerVisible}
          saveLoading={saveLoading}
          handleDeleteClick={handleDeleteClick}
          handleActiveClick={handleActiveClick}
          handleRemoveFromSelected={handleRemoveFromSelected}
          toggleActive={toggleActive}
        />
      ) : (
        <ContainerRow device={true}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px'
            }}
          >
            <TitleRow style={{ justifyContent: 'space-between' }}>
              <div>
                <CustomTypography color={theme['@text-color']}>
                  Dictation
                </CustomTypography>
              </div>
              <div>
                <K2pButtonsecnd
                  shape='round'
                  onClick={fetchAuthorData}
                  icon={<ArrowDownOutlined className='blue-button' />}
                >
                  Import From Global List
                </K2pButtonsecnd>
                <AddButton
                  icon={<PlusOutlined />}
                  type='primary'
                  shape='round'
                  onClick={handleCreate}
                >
                  New Dictation Template
                </AddButton>
              </div>
            </TitleRow>
            <CustomModal
              title='Import Dictation Template'
              visible={modalVisible}
              onCancel={handleCancel}
              // bodyStyle={{ height: '540px' }}
              footer={[
                <AddButton key='cancel' shape='round' onClick={handleCancel}>
                  Cancel
                </AddButton>,
                <AddButton
                  key='import'
                  type='primary'
                  icon={<DownloadOutlined />}
                  shape='round'
                  onClick={isConflict}
                >
                  Import selected
                </AddButton>
              ]}
            >
              <Row gutter={[16, 16]} style={{ marginRight: '2px' }}>
                <Col span={9}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative'
                    }}
                  >
                    <SearchInputStylee
                      placeholder='Search'
                      prefix={<SearchOutlined />}
                      onChange={handleSearchAuthor}
                      style={{
                        marginBottom: '10px',
                        paddingLeft: '29px'
                      }}
                    />
                    <SearchDivider></SearchDivider>
                    {loadingAuthor ? (
                      <Spinner height={'52vh'} />
                    ) : cannedTextAuthor == 0 ? (
                      'No data available'
                    ) : (
                      <div
                        style={{
                          maxHeight: '440px',
                          overflowY: 'auto',
                          marginLeft: '17px'
                        }}
                        onScroll={handleScrollAuthor}
                      >
                        {cannedTextAuthor.map(item => (
                          <KpCol
                            key={item.templateName}
                            onClick={() => handleCardClick(item)}
                            className='card-item'
                            // style={{ backgroundColor: '#f5f7fa ' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '6px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline'
                                }}
                              >
                                <Checkbox
                                  checked={checkedCards.some(
                                    data => data.id === item.id
                                  )}
                                  onChange={e =>
                                    handleCheckboxChange(item, e.target.checked)
                                  }
                                />
                                <h3 className='keyvalue'>
                                  <TruncatedText
                                    text={item.templateName}
                                    maxLength={25}
                                  />
                                </h3>
                              </div>
                              <Tooltip
                                placement='topRight'
                                title={
                                  <div>
                                    <ExclamationCircleOutlined
                                      style={{ color: 'orange' }}
                                    />
                                    <span style={{ marginLeft: 8 }}>
                                      A K2P with this name already exist in your
                                      collection
                                    </span>
                                  </div>
                                }
                              >
                                {item.conflict && (
                                  <ExclamationCircleOutlined
                                    style={{
                                      color: '#E17F37',
                                      marginLeft: 'auto'
                                    }}
                                  />
                                )}
                              </Tooltip>
                            </div>
                            <div className='author-box'>
                              By: {item.author.doctorName}
                            </div>{' '}
                          </KpCol>
                        ))}
                      </div>
                    )}
                  </div>
                  <Divider
                    type='vertical'
                    style={{
                      height: '100%',
                      position: 'absolute',
                      right: 0,
                      top: 0
                    }}
                  />
                </Col>

                <Col
                  span={15}
                  style={{ backgroundColor: '#F5F7FA', height: '490px' }}
                >
                  {selectedCard && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col span={24} style={{ marginTop: '12px' }}>
                          <h5
                            className='header'
                            style={{
                              marginLeft: 8,
                              fontFamily: 'Krub',
                              fontWeight: 600
                            }}
                          >
                            Template Name
                          </h5>
                          <h2>{selectedCard.templateName}</h2>
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        ></Col>
                      </Row>
                      <Divider style={{ margin: '16px 0' }} />
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <h5>Headings</h5>
                          {/* <p>{selectedCard.templateDescription}</p> */}
                          <MappedValuesContainer>
                            {selectedCard.sectionHeadings.map((item, index) => {
                              return (
                                <>
                                  <MappedValues form={true}>
                                    <span one={true}>
                                      {item.customUIHeading}
                                    </span>
                                    <SwapOutlined />
                                    <span two={true}>{item.systemHeading}</span>
                                  </MappedValues>
                                </>
                              );
                            })}
                          </MappedValuesContainer>
                          {/* <p>{selectedCard.phrase}</p> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </CustomModal>
            <BreadCrumbComponent items={BreadCrubItems} />
          </div>
          <TemplateWrapper fullWidth={loading}>
            <>
              <ConfigDictationRow
                gutter={16}
                style={{ borderTop: '1px solid #e1e4eb' }}
              >
                <DictationMineCol span={9}>
                  <SubmitCol style={{ border: 'none' }}>
                    <CardTitle>My Collection</CardTitle>
                    <SearchInputStyle
                      placeholder='Search'
                      prefix={<SearchOutlined />}
                      // onChange={event => debounceOnChange(event.target.value)}
                      onChange={handleSearch}
                      style={{ width: '100%', marginBottom: '5px' }}
                    />
                    <Divider></Divider>
                    <DndProvider backend={HTML5Backend}>
                      <DnDColumn
                        style={{ overflow: 'scroll' }}
                        onScroll={handleScroll}
                      >
                        {loading ? (
                          <Spinner height={'70vh'} />
                        ) : keyToPhraseItems.length == 0 ? (
                          'No data available'
                        ) : (
                          <div>
                            {returnItemsForColumn1(KEY_TO_PHRASE)}
                            {loadingMineScroll ? (
                              <Spinner height={'20vh'} />
                            ) : null}
                          </div>
                        )}
                      </DnDColumn>
                    </DndProvider>
                  </SubmitCol>
                </DictationMineCol>

                <ActiveSectionCol
                  span={15}
                  className='activesection'
                  style={{
                    backgroundColor: '#edeff4'
                  }}
                >
                  <h4 className='active-k2p-head'>Active Dictation template</h4>
                  <SubmitCol
                    bordered={false}
                    style={{
                      backgroundColor: '#edeff4',
                      overflowY: 'scroll',
                      marginBottom: '20px'
                    }}
                    onScroll={handleScrollActive}
                  >
                    {loader ? (
                      <Spinner height={'70vh'} />
                    ) : (
                      <>
                        <ActiveKeytophraseCol
                          bordered={false}
                          style={{ backgroundColor: '#edeff4' }}
                        >
                          {loadingActive ? (
                            <Spinner height={'70vh'} />
                          ) : (
                            <DndProvider backend={HTML5Backend}>
                              <Column title={ACTIVE_KEY_TO_PHRASE}>
                                {/* {returnItemsForColumn(ACTIVE_KEY_TO_PHRASE)} */}
                                {selectedFacilityItem.length == 0
                                  ? 'No data available'
                                  : returnItemsForColumn(ACTIVE_KEY_TO_PHRASE)}
                                {loadingActiveScroll ? (
                                  <Spinner height={'20vh'} />
                                ) : null}
                              </Column>
                            </DndProvider>
                          )}
                        </ActiveKeytophraseCol>
                      </>
                    )}
                  </SubmitCol>
                </ActiveSectionCol>
              </ConfigDictationRow>
            </>
          </TemplateWrapper>
        </ContainerRow>
      )}
      {/* <CustomDictationModal
        title={selectedItem ? 'Edit Template' : 'Add Template'}
        visible={addDrawerVisible}
        // centered
        onCancel={() => {
          setEditItem(null);
          setAddDrawerVisible(false);
        }}
        footer={null}
        destroyOnClose={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        width={400}
        key={addDrawerVisible}
      > */}

      {/* </CustomDictationModal> */}

      <Keytophraseduplication
        title='Import Key-to-Phrases'
        visible={errorModalVisible}
        onCancel={toggleErrorModal}
        footer={null}
        centered
      >
        <div style={{ flex: 1, marginBottom: '20px' }}>
          The names of certain key-to-phrases you are attempting to import are
          already present in your collection. Please choose whether to skip or
          replace the conflicting key-to-phrases.
        </div>
        <div className='warning-container' style={{ marginBottom: '20px' }}>
          <ExclamationCircleOutlined style={{ color: 'orange' }} />
          <div className='warning-message'>
            Replacing will permanently remove those key-to-phrases from your
            Collection
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px'
          }}
        >
          <AddButton type='primary' shape='round' onClick={toggleErrorModal}>
            Change selection
          </AddButton>
          <AddButton shape='round' onClick={handleSkip}>
            Skip those
          </AddButton>
          <Button danger shape='round' onClick={handleReplace}>
            Replace those
          </Button>
        </div>
      </Keytophraseduplication>
      <ConfirmationModal
        visible={showDeleteConfirmation}
        onOk={() => deleteTemplate()}
        onCancel={() => {
          setShowDeleteConfirmation(false);
          setDeleteItem(null);
        }}
        loading={deleteLoading}
        confirmation={true}
        antdModalProps={{ closable: false, mask: false }}
      >
        <DeleteIcon />
        <DeleteConfirmationMessage>
          Are you sure you want to delete?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
    </>
  );
};

export default DictationTemplate;
