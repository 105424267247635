import React, { useState } from 'react';
import { FloatLabel as FloatLabelDiv, Label } from './styledComponents';
import { useSelector } from 'react-redux';
import { StyledInput, StyledSelect } from '../CustomInput/styledComponents';
import { Select } from 'antd';
import Cleave from 'cleave.js/react';

const FloatLabel = ({
  value = {},
  onChange,
  primaryColor,
  label,
  type,
  countries,
  disabled,
  meta,
  creditCardProps,
  clinic,
  field
}) => {
  const [focus, setFocus] = useState(false);
  const [theme] = useSelector(state => [state.themeReducer]);

  const triggerChange = changedValue => {
    if (onChange) {
      onChange({ ...value, ...changedValue });
    }
  };

  const onSelectChange = changedValue => {
    triggerChange({ changedValue });
  };

  return (
    <FloatLabelDiv
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {type === 'select' && (
        <StyledSelect
          primaryTextColor={theme['@text-color']}
          secondaryTextColor={theme['@text-color-secondary']}
          size='large'
          onChange={onSelectChange}
          value={
            value && value.changedValue && value.changedValue.length > 0
              ? value.changedValue
              : ''
          }
          disabled={disabled}
          showSearch
          getPopupContainer={trigger => trigger.parentNode}
        >
          {countries && countries.length > 0
            ? countries.map(({ name }) => (
                <Select.Option value={name}>{name}</Select.Option>
              ))
            : meta &&
              meta.map(item => {
                return (
                  <Select.Option value={item.value}>{item.name}</Select.Option>
                );
              })}
        </StyledSelect>
      )}

      {type === 'input' && (
        <StyledInput
          primaryTextColor={theme['@text-color']}
          secondaryTextColor={theme['@text-color-secondary']}
          size='large'
          value={value && value.length > 0 ? value : ''}
          onChange={triggerChange}
          disabled={disabled}
        />
      )}

      {type === 'password' && (
        <StyledInput.Password
          primaryTextColor={theme['@text-color']}
          secondaryTextColor={theme['@text-color-secondary']}
          size='large'
          value={value && value.length > 0 ? value : ''}
          onChange={triggerChange}
          disabled={disabled}
          readOnly={clinic ? true : false}
          // onPaste={e => {
          //   e.preventDefault();
          // }}
          visibilityToggle={field === 'clientSecret' ? false : true}
        />
      )}

      {type === 'creditCard' && (
        <Cleave
          {...creditCardProps}
          primaryTextColor={theme['@text-color']}
          secondaryTextColor={theme['@text-color-secondary']}
          size='large'
          class='cleave-input'
          onChange={triggerChange}
          value={value && value.length > 0 ? value : ''}
        />
      )}

      <Label
        value={value && value.changedValue ? value.changedValue : value}
        focus={focus}
        primaryColor={primaryColor ? primaryColor : theme['@primary-color']}
        secondaryTextColor={theme['@text-color-secondary']}
      >
        {label}
      </Label>
    </FloatLabelDiv>
  );
};

export default FloatLabel;
